.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
}

.scroll-to-top-button {
    align-content: center;
    height: 50px;
    width: 50px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 100%;
    border: 3px solid rgb(189, 79, 203);
}

.scroll-to-top-button svg {
    color: rgb(189, 79, 203)
}
