.back-label{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    color: rgb(82, 82, 91);
    line-height: 30px;
    display: block;
    flex-direction: column;
    justify-content: unset;
    /* width: 546px; */
    height: unset;
    gap: unset;
    align-items: unset;
    flex-shrink: 0;
    position: relative;
    padding: 0px;
    white-space: pre-wrap;
}

.back-label-link{
    color: 'BD4FCB',
}

.amplify-button.contactus-submit-button {
    border-radius: 10px;
    transition: background-color 0.3s;
    border: 3px solid rgb(189, 79, 203);
    background-color: rgb(189, 79, 203);
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;    
    text-transform: capitalize;  
}

.amplify-button.contactus-submit-button:hover {
    background-color: rgb(87, 17, 112);
}

.amplify-button.contactus-clear-button {
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 3px solid rgb(87, 17, 112);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;    
    text-transform: capitalize;  
}

.amplify-button.contactus-clear-button:hover {
    background-color: rgb(189, 79, 203);
}

.amplify-field .amplify-label {
    text-align: left;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;    
    text-transform: capitalize;  
}

.amplify-field .amplify-input {
    text-align: left;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;    
    text-transform: capitalize;  
}

.amplify-field .amplify-select {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;    
    text-transform: capitalize;  
}

.amplify-field .amplify-select option {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;    
    text-transform: capitalize;  
}