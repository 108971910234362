.small-transparent-button {
    border-radius: 100px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 3px solid rgb(87, 17, 112);
    background-color: transparent;
    color: rgb(87, 17, 112);
}

.small-transparent-button-default {
    width: 300px;
    height: 76px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;    
    text-transform: capitalize;    
}

.small-transparent-button-small {
    width: 291px;
    height: 45px;    
    font-family: Montserrat;
    font-size: 15px;
}

.small-transparent-button:hover {
    background-color: rgb(189, 79, 203);
}
