.header-purple-button {
    border-radius: 100px;
    cursor: pointer; 
    transition: background-color 0.3s;
    border: 3px solid rgb(189, 79, 203);
    background-color: rgb(189, 79, 203);
    color: rgb(255, 255, 255);
}

.header-purple-button-default {
    width: 176px;
    height: 50px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    text-transform: capitalize;
    line-height: 21.942px;
    text-align: center;
}

.header-purple-button-small {
    width: 176px;
    height: 50px; 
    font-family: Montserrat;
    font-size: 15px;
}

.header-purple-button:hover {
    background-color: rgb(87, 17, 112);
}
