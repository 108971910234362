.small-purple-button {
    border-radius: 100px;
    cursor: pointer; 
    transition: background-color 0.3s;
    border: 3px solid rgb(189, 79, 203);
    background-color: rgb(189, 79, 203);
    color: rgb(255, 255, 255);
}

.small-purple-button-default {
    width: 300px;
    height: 76px; 
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;    
    text-transform: capitalize;   
}

.small-purple-button-small {
    width: 291px;
    height: 45px;   
    font-family: Montserrat;
    font-size: 15px;
}

.small-purple-button:hover {
    background-color: rgb(87, 17, 112);
}
